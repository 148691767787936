import Slider from "react-slick";
import photos from "../../configs/dataBlueprint";

import CloseBtn from "../../images/close_btn.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./styles.css";
import { useState } from "react";

export default function BannerPromo() {
   let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      adaptiveHeight: true,
      responsive: [
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false,
            },
         },
      ],
   };

   const [modalZoomImage, setModalZoomImage] = useState(false);
   const [modalImg, setModalImg] = useState(null);

   function handleOpenImg(e) {
      e.preventDefault();

      setModalZoomImage(true);
      setModalImg(e.target.src);
   }

   return (
      <div className="banner-promo">
         <div className="banner-promo-title">
            <p className="banner-promo-info resa-bold txt-white uppercase">
               Apartamentos <br /> de 44 a 75m²
            </p>
            <a
               href="#form"
               className=" banner-promo-link resa-bold txt-white uppercase"
            >
               Fale com um <br />
               consultor
            </a>
         </div>
         <div className="banner-promo-blueprint-carousel">
            <Slider {...settings}>
               {photos.map((photo) => (
                  <img
                     onClick={(e) => handleOpenImg(e)}
                     key={photo.id}
                     className={`banner-carousel-img`}
                     src={photo.photo}
                     alt="Imagem da Sala de Jantar"
                  />
               ))}
            </Slider>
         </div>
         <>
            {modalZoomImage && (
               <div className="banner-promo-modal">
                  <img
                     src={CloseBtn}
                     alt="fechar"
                     className="banner-promo-closebtn"
                     onClick={() => setModalZoomImage(false)}
                  />
                  <img
                     className="banner-promo-modal-img"
                     src={modalImg}
                     alt=""
                  />
               </div>
            )}
         </>
      </div>
   );
}

import Slider from "react-slick";
import photos from "../../configs/dataPhotos";
import "./styles.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function NewCarousel() {
   let settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0px",
      slidesToShow: 3,
      speed: 500,
      arrows: true,
      useTransform: true,
      focusOnSelect: true,
      autoplay: true,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 3,
               infinite: true,
               dots: true,
               arrows: false,
            },
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               initialSlide: 2,
               arrows: false,
            },
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false,
            },
         },
      ],
   };

   return (
      <div className="new-carousel-container">
         <p className="new-carousel-title txt-bigger">AMBIENTES</p>
         <div className="new-carousel">
            <Slider {...settings}>
               {photos.map((photo) => (
                  <img
                     key={photo.id}
                     className={`banner-carousel-img`}
                     src={photo.photo}
                     alt="Imagem da Sala de Jantar"
                  />
               ))}
            </Slider>
         </div>
      </div>
   );
}

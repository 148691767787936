import foto1 from "../images/image-apt-one.jpg";
import foto2 from "../images/image-apt-two.jpg";
import foto3 from "../images/image-apt-three.jpg";
import foto4 from "../images/image-apt-four.jpg";
import foto5 from "../images/image-apt-five.jpg";
import foto6 from "../images/image-apt-six.jpg";

const photos = [
   {
      id: "1",
      photo: foto1,
      type: "img",
   },
   {
      id: "2",
      photo: foto2,
      type: "img",
   },
   {
      id: "3",
      photo: foto3,
      type: "img",
   },
   {
      id: "4",
      photo: foto4,
      type: "img",
   },
   {
      id: "5",
      photo: foto5,
      type: "img",
   },
   {
      id: "6",
      photo: foto6,
      type: "img",
   },
];
export default photos;

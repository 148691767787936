import Maps from "../../images/maps.png";
import PinIcon from "../../images/pin_icon.png";
import BackgroundMapsMerchan from "../../images/backgroundBannerMerchan.png";

import "./styles.css";

export default function MapLocalization() {
   return (
      <div className="maps-site">
         <div className="maps-banner-merchan">
            <p className=" txt-big maps-banner-merchan-title">
               Você em uma das melhores localizações de Recife
            </p>
            <p className="maps-banner-merchan-subtitle">
               Tenha toda comodidade de viver a apenas alguns minutos de tudo o
               que você precisa no seu dia a dia.
            </p>
         </div>
         <img
            className="maps-merchan-img"
            src={BackgroundMapsMerchan}
            alt="Cidade vista de cima"
         />
         <div className="maps-bot-info">
            <img src={PinIcon} alt="" />
            <p className="txt-bold txt-big maps-bot-text">
               R. Prof. Othon Paraíso, 123 - Torreão - Recife
            </p>
         </div>
         <a href="https://goo.gl/maps/2a2JgQ2CFfsWQ3iK6" target="__blank">
            <img className="maps-img" src={Maps} alt="Localização do Prédio" />
         </a>
         {/* <div className="maps-information">
            <div className="maps-bot-info">
              <img src={CashIcon} alt="" />
               <p className="txt-bold txt-white maps-bot-text">
                  MELHOR CUSTO <br />
                  BENEFÍCIO DA REGIÃO
               </p>
            </div>
            <div className="maps-bot-info">
               <img src={LeisureIcon} alt="" />
               <p className="txt-bold txt-white maps-bot-text">
                  ROOFTOP COM <br />
                  600M² DE LAZER
               </p>
            </div>
            <div className="maps-bot-info">
               <img src={HouseIcon} alt="" />
               <p className="txt-bold txt-white maps-bot-text">
                  CASA DE PRAIA COM <br />
                  CONFORTO DE HOTEL
               </p> 
            </div>
         </div>*/}
      </div>
   );
}

import { useState } from "react";
import inputMask from "@nafuzi/brazilian-masks";
import axios from "../../configs/axios";
import userPolicy from "../../docs/userPolicy.pdf";

import Popup from "../Popup";

import "./styles.css";

export default function Form() {
   const urlToSendForm = "/form";

   const [modalOpen, setModalOpen] = useState(false);
   const [isChecked, setIsChecked] = useState(false);

   const [error, setError] = useState("");
   const [success, setSuccess] = useState("");
   const [buttonString, setButtonString] = useState("CADASTRAR");

   const [userData, setUserData] = useState({
      nome: "",
      email: "",
      telefone: "",
      cliente: "noemia",
   });

   function handleOnChange() {
      setIsChecked(!isChecked);
      return;
   }
   function handleOpeningModal() {
      document.body.style.overflow = "hidden";
      setModalOpen(true);
   }

   function updateInputValue(e) {
      if (e.target.name === "telefone") {
         e.target.value = inputMask.phone(e.target.value);
      }

      setUserData({
         ...userData,
         [e.target.name]: e.target.value,
      });
   }

   async function sendForm() {
      try {
         console.log(userData);
         await axios.post(urlToSendForm, userData);
         setButtonString(`CADASTRAR`);
         setSuccess("Cadastro feito!");
         return;
      } catch (error) {
         if (error) {
            setButtonString("CADASTRAR");
            setError(error.message);
         }
         if (error.response.status >= 500) {
            console.log(error.response.status);
            setButtonString("CADASTRAR");
            setError("Erro ao conectar com o servidor");
         }
         console.log(error);
      }
   }

   function checkFields(e) {
      e.preventDefault();
      setError("");

      userData.nome = userData.nome.trim();
      userData.telefone = userData.telefone.trim();
      userData.email = userData.email.trim();

      if (!userData.nome) {
         setError("Digite seu nome");
         return;
      }
      if (
         !userData.nome.match(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/)
      ) {
         setError("O nome deve conter apenas letras");
         return;
      }

      if (!userData.email) {
         setError("Digite seu email");
         return;
      }
      if (!userData.email.includes("@")) {
         setError("Digite um email válido");
         return;
      }
      if (userData.telefone.length < 11) {
         setError("Telefone deve ter 11 dígitos");
         return;
      }
      if (!isChecked) {
         setError("Leia os termos de uso");
         return;
      }

      setUserData({
         nome: "",
         email: "",
         telefone: "",
         cliente: "Noemia",
      });
      setButtonString(`● ● ●`);
      sendForm();
      console.log("Enviando formulario");
   }

   return (
      <div className="header-form">
         <p className="header-form-title txt-bold txt-normal">
            Saiba mais sobre o empreendimento
         </p>
         <form onSubmit={checkFields}>
            <input
               className="form-input txt-small"
               type="text"
               onChange={(e) => updateInputValue(e)}
               placeholder="Nome"
               name="nome"
               value={userData.nome}
            />
            <input
               className="form-input txt-small"
               type="email"
               onChange={(e) => updateInputValue(e)}
               inputMode="email"
               placeholder="Email"
               name="email"
               value={userData.email}
            />
            <input
               className="form-input txt-small"
               onChange={(e) => updateInputValue(e)}
               type="text"
               placeholder="Telefone/Whatsapp"
               name="telefone"
               value={userData.telefone}
            />
            <div className="lgpd-section">
               <input
                  className="check-lgpd"
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleOnChange}
               />
               <a
                  className="txt-small mobile-anchor"
                  style={{
                     fontWeight: "bold",
                     textDecoration: "underline",
                  }}
                  href={userPolicy}
                  target="_blank"
                  rel="noreferrer"
               >
                  Eu concordo em receber comunicações
               </a>
               <p
                  onClick={() => handleOpeningModal()}
                  className="txt-small anchor-policy"
                  style={{
                     fontWeight: "bold",
                     textDecoration: "underline",
                  }}
               >
                  Eu concordo em receber comunicações
               </p>
            </div>
            <button className="btn-normal txt-normal">{buttonString}</button>
            <div className="feedback-form">
               <span
                  className={
                     error
                        ? `txt-small error-form-enable`
                        : `txt-small error-form-disable`
                  }
               >
                  * {error}
               </span>
               <span
                  className={
                     success
                        ? `txt-small success-form-enable`
                        : `txt-small success-form-disable`
                  }
               >
                  * {success}
               </span>
            </div>
         </form>
         {modalOpen && <Popup setModalOpen={setModalOpen} />}
      </div>
   );
}

import "./styles.css";
import LogoNoemia from "../../images/logo_site.png";
import LogoGrouped from "../../images/logos_grouped.png";
import Form from "../Form";

function Footer() {
   return (
      <div className="footer-site">
         <div className="footer-logos">
            <div className="footer-logos-imgs">
               <img
                  className="footer-max-derby-logo"
                  src={LogoNoemia}
                  alt="Logo Noemia"
               />
               <img
                  className="footer-max-derby-logo"
                  src={LogoGrouped}
                  alt="Logo Noemia"
               />
            </div>
            <div className="footer-contact txt-big txt-bold">
               <Form />
               {/* <a href="tel:83998270035">
                  <span className="txt-white resa-bold footer-contact-number">
                     (81) 9 9827-0035
                  </span>
               </a> 
                <a
                  href="https://www.maxplural.com.br"
                  target="_blank"
                  rel="noreferrer"
                  className="txt-white resa-bold"
               >
                  www.maxplural.com.br
               </a>  */}
            </div>
         </div>
      </div>
   );
}

export default Footer;

import "./styles.css";

import Header from "../../components/Header";
import MapLocalization from "../../components/MapLocalization";
import ApartmentDetails from "../../components/ApartmentDetails";
import Footer from "../../components/Footer";
import FloatingPanel from "../../components/FloatingPanel";
import BannerPromo from "../../components/BannerPromo";
import NewCarousel from "../../components/NewCarousel";

function Home() {
   return (
      <div className="homepage-site">
         <FloatingPanel />
         <Header />
         <ApartmentDetails />
         <NewCarousel />
         <BannerPromo />
         <MapLocalization />
         <Footer />
      </div>
   );
}

export default Home;

import LogoSite from "../../images/logo_site.png";
import Form from "../Form";
import MainTitle from "../../images/main-title.png";
import Building from "../../images/building.png";

import "./styles.css";

function Header() {
   return (
      <div id="form" className="header-site">
         <div className="header-content">
            <img
               className="header-building"
               src={Building}
               alt="imagem representativa da construção"
            />
            <div className="header-merchan">
               <img className="header-logo" src={LogoSite} alt="logo do site" />
               <div className="header-title">
                  <img
                     className="header-main-title-img"
                     src={MainTitle}
                     alt=""
                  />
                  <Form />
               </div>
            </div>
         </div>
      </div>
   );
}

export default Header;

import ImageOne from "../../images/image-apt-one.jpg";
import ImageTwo from "../../images/image-apt-two.jpg";
import ImageThree from "../../images/image-apt-three.jpg";
import ImageFour from "../../images/image-apt-four.jpg";
import ImageFive from "../../images/image-apt-five.jpg";
import ImageSix from "../../images/image-apt-six.jpg";

import "./styles.css";
import { useState } from "react";

export default function ApartmentDetails() {
   const apartmentImages = [
      {
         id: 0,
         place: "Cowork",
         src: ImageOne,
      },
      {
         id: 1,
         place: "Kids",
         src: ImageTwo,
      },
      {
         id: 2,
         place: "Piscina",
         src: ImageThree,
      },
      {
         id: 3,
         place: "Academia",
         src: ImageFour,
      },
      {
         id: 4,
         place: "Market",
         src: ImageFive,
      },
      {
         id: 5,
         place: "Salão",
         src: ImageSix,
      },
   ];

   const [selectedImageIndex, setSelectedImageIndex] = useState(0);
   const [backgroundImg, setBackgroundImg] = useState(ImageOne);

   const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setBackgroundImg(apartmentImages[index].src);
   };

   return (
      <div className="apartment-details-icons txt-big txt-white mazz-bold">
         <div className="apartment-inner-frame">
            <p className="apartment-details-title txt-bold txt-bigger">
               A sua melhor escolha na zona norte
            </p>
            <p className="apartment-details-subtitle txt-normal">
               O Noêmia Paraíso une design, mobilidade, lazer e qualidade de
               vida em um só empreendimento. Tudo isso na região que mais cresce
               no Recife. Venha conhecer essa oportunidade!
            </p>
         </div>
         <div className="apartment-container-img-selector">
            <img
               className={`apartment-selected-image anim-img`}
               src={backgroundImg}
               alt=" "
            />
            <div className="apartment-details">
               <div className="apartment-right-panel">
                  <div className="apartment-showcase">
                     <div className="apartment-generated-row">
                        {apartmentImages.map((img) => (
                           <div className="apartment-container" key={img.id}>
                              <img
                                 className={`apartment-img-background ${
                                    img.id === selectedImageIndex
                                       ? "bg-selected"
                                       : ""
                                 }`}
                                 src={img.src}
                                 alt="Imagem da "
                                 onClick={() => handleImageClick(img.id)}
                              />
                              <span
                                 className={`apartment-place-name ${
                                    img.id === selectedImageIndex
                                       ? "place-selected"
                                       : ""
                                 }`}
                              >
                                 {img.place}
                              </span>
                           </div>
                        ))}
                     </div>
                     {/* <div className="left-row">
                     <div
                        className="bg"
                        onClick={(e) => handleChangeBackground(e)}
                     >
                        <img
                           className="apartment-img-background"
                           src={ImageOne}
                           alt="Imagem da "
                        />
                     </div>
                     <div
                        className="bg"
                        onClick={(e) => handleChangeBackground(e)}
                     >
                        <img
                           className="apartment-img-background"
                           src={ImageTwo}
                           alt="Imagem da "
                        />
                     </div>
                     <div
                        className="bg"
                        onClick={(e) => handleChangeBackground(e)}
                     >
                        <img
                           className="apartment-img-background"
                           src={ImageThree}
                           alt="Imagem da "
                        />
                     </div>
                  </div>
                  <div className="right-row">
                     <div
                        className="bg"
                        onClick={(e) => handleChangeBackground(e)}
                     >
                        <img
                           className="apartment-img-background"
                           src={ImageFour}
                           alt="Imagem da "
                        />
                     </div>
                     <div
                        className="bg"
                        onClick={(e) => handleChangeBackground(e)}
                     >
                        <img
                           className="apartment-img-background"
                           src={ImageFive}
                           alt="Imagem da "
                        />
                     </div>
                     <div
                        className="bg"
                        onClick={(e) => handleChangeBackground(e)}
                     >
                        <img
                           className="apartment-img-background"
                           src={ImageSix}
                           alt="Imagem da "
                        />
                     </div>
                  </div> */}
                  </div>
                  <a href="#form" className="apartment-contact-button">
                     Fale com um consultor
                  </a>
               </div>
            </div>
         </div>
      </div>
   );
}
